<template>
  <div class="detail-content">
    <div class="detail-card-container">
      <div class="detail-card-header">
        <h4>Politicas</h4>
      </div>
      <div class="detail-card-content">
        <p>{{ programContent.description }}</p>
        <h4>Autorizacion</h4>
        <p>
          Si hace un esfuerzo de buena fe para cumplir con esta política durante
          su investigación de seguridad, consideraremos que su investigación
          está autorizada, trabajaremos con usted para comprender y resolver el
          problema rápidamente, y el nombre de la agencia no recomendará ni
          emprenderá acciones legales relacionadas con su investigación. En caso
          de que un tercero inicie una acción legal en su contra por actividades
          que se llevaron a cabo de acuerdo con esta política, daremos a conocer
          esta autorización.
        </p>
        <h4>Guia de uso</h4>
        <p>
          Bajo esta politica, "investigacion" significa actividades en donde tu:
        </p>
        <ul>
          <li>
            Notificar a JAMA Security lo mas pronto posible despues de encontrar
            una vulnerabilidad de seguridad critica.
          </li>
          <li>
            Hacer todo lo posible para evitar violaciones de privacidad,
            degradación de la experiencia del usuario, interrupción de los
            sistemas de producción y destrucción o manipulación de datos.
          </li>
          <li>
            Utilice exploits solo en la medida necesaria para confirmar la
            presencia de una vulnerabilidad. No utilice un exploit para
            comprometer o exfiltrar datos, establecer un acceso persistente a la
            línea de comandos ni utilizar el exploit para cambiar a otros
            sistemas.
          </li>
          <li>
            Brindar un período de tiempo razonable para resolver el problema
            antes de divulgarlo públicamente.
          </li>
          <li>No enviar un gran volumen de informes de baja calidad.</li>
        </ul>
        <h4>Alcances / Scope</h4>
        <p>
          Cualquier servicio que no se mencione expresamente , como cualquier
          servicio conectado, está excluido del alcance y no está autorizado
          para pruebas. Además, las vulnerabilidades encontradas en los sistemas
          de nuestros proveedores quedan fuera del alcance de esta política y
          deben informarse directamente al proveedor de acuerdo con su política
          de divulgación (si corresponde). Si no está seguro de si un sistema
          está dentro del alcance o no, comuníquese con nosotros a
          security@jamasecurity.gov antes de comenzar su investigación (o al
          contacto de seguridad para el nombre de dominio del sistema que figura
          en el WHOIS). Aunque desarrollamos y mantenemos otros sistemas o
          servicios accesibles a través de Internet, solicitamos que solo se
          realicen investigaciones y pruebas activas en los sistemas y servicios
          cubiertos por el alcance de este documento. Si hay un sistema en
          particular que no está dentro del alcance y cree que merece ser
          probado, comuníquese con nosotros para discutirlo primero.
          Aumentaremos el alcance de esta política con el tiempo.
        </p>
      </div>
    </div>
    <div class="scope-container detail-card-container">
      <div class="detail-card-header">
        <h4>Alcances / Scope</h4>
      </div>
      <div class="scope-content">
        <div class="scope-list">
          <table>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr
              v-for="(item, $i) in programContent.scopes"
              :key="$i"
              class="scope-list-item"
            >
              <td>{{ item.type }}</td>
              <td>{{ item.description }}</td>
              <td>
                <div class="status critical">
                  <span>Critical</span>
                </div>
              </td>
              <td>
                <div class="status bounty">
                  <span>Bounty</span>
                </div>
              </td>
            </tr>
          </table>

          <h4 class="out-of-scope">Out of Scope</h4>
          <div class="out-of-scope">
            <span>{{ detail.outOfScopes }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "programDetailContent",
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    programContent() {
      return this.$store.state.programDetail.content;
    },
  },
};
</script>

<style></style>
